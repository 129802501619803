import { UserDataRequest } from '@amedia/user';
import './userjs-fallback-interface.js';
import './amedia-user-arrow.js';
import './amedia-username.js';
import './amedia-aid-menu.js';

function stringToHTML(html) {
  return new DOMParser().parseFromString(html, 'text/html').body.firstChild;
}

class AmediaUser extends HTMLElement {
  async init() {
    this.disable = window.location.href.indexOf('disable_aid_menu=true') > -1;
    this.initialized = true;
    this.publication = this.getAttribute('publication');
    this.locale = this.getAttribute('locale') || '';
    this.mypage = this.getAttribute('mypage') || '';
    this.links = this.getAttribute('links');
    this.orderpage = this.getAttribute('orderpage') || '';
    this.subscriptionLink = this.getAttribute('subscription-link') || '';
    this.subscriptionText = this.getAttribute('subscription-text') || '';
    this.theme = this.getAttribute('theme');
    this.mockUser = this.getAttribute('mockuser');

    // Hack until pluss domain is removed
    if (window.location.hostname.includes('pluss.nettavisen.no')) {
      this.publication = 'pluss.nettavisen.no';
    }
    if (window.location.hostname.includes('www.nettavisen.no')) {
      this.publication = 'www.nettavisen.no';
    }

    if (this.mockUser) {
      this.render();
    } else {
      let userdata = new UserDataRequest();
      if (userdata.withContext) {
        userdata = userdata.withContext('ego');
      }
      userdata
        .withAttributes(['name', 'access'])
        .subscribe(({ attributes, state }) => {
          if (state.isLoggedIn !== true && state.isLoggedIn !== false) {
            if (state.emergencyMode && state.emergencyMode.includes('aid')) {
              this.renderEmergencyMode(
                state.emergencyMode.includes('firedrill-in-progress')
              );
            }
            return;
          }
          this.isLoggedIn = state.isLoggedIn;
          this.user = {
            name: attributes.name || 'Innlogget',
            isEmployee: !!attributes.access?.includes('amedia_staff'),
          };
          this.render();
        });
    }
  }

  connectedCallback() {
    if (!this.initialized) {
      this.init();
    }
  }

  render() {
    this.querySelector('.emergency-mode')?.delete();
    if (this.disable) {
      this.setAttribute('style', 'display:none');
      return null;
    }

    let attr;
    if (this.isLoggedIn) {
      attr = `authenticated username="${this.user.name}" isEmployee="${this.user.isEmployee}"`;
    } else if (this.mockUser) {
      attr = `authenticated username="${this.mockUser}" isMockUser="true"`;
    } else {
      attr = `orderpage="${this.orderpage}" publication="${this.publication}" subscription-text="${this.subscriptionText}" subscription-link="${this.subscriptionLink}" links="${this.links}"`;
    }
    const usernameHTML = `<amedia-username ${attr} theme="${this.theme}"></amedia-username>`;
    const aidMenuHTML = `<amedia-aidmenu
                class="${this.theme}"
                locale="${this.locale}"
                publication="${this.publication}"
                mypage="${this.mypage}"
                links="${this.links}"
                isLoggedIn="${this.isLoggedIn}"
            </amedia-aidmenu>`;

    if (this.classList.contains('loaded')) {
      this.querySelector('amedia-username').replaceWith(
        stringToHTML(usernameHTML)
      );
      this.querySelector('amedia-aidMenu').replaceWith(
        stringToHTML(aidMenuHTML)
      );
    } else {
      this.insertAdjacentHTML('afterbegin', `${usernameHTML}${aidMenuHTML}`);
      this.classList.add('loaded');
    }

    return this;
  }

  renderEmergencyMode(isFireDrill) {
    if (!this.querySelector('.emergency-mode')) {
      this.insertAdjacentHTML(
        'afterbegin',
        `<span class="emergency-mode">${
          isFireDrill
            ? 'Planlagt systemtest, straks tilbake'
            : 'Tekniske problemer'
        }</span>`
      );
    }
  }
}

if (customElements && customElements.define) {
  customElements.define('amedia-user', AmediaUser);
} else {
  document.registerElement('amedia-user', { prototype: AmediaUser.prototype });
}
