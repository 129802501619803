import { aidUrls, logout } from '@amedia/user';

let transition;
if ('ontransitionend' in window) {
  transition = 'transitionend';
} else if ('onwebkittransitionend' in window) {
  transition = 'webkitTransitionEnd';
} else {
  transition = false;
}

class AidMenu extends HTMLElement {
  init() {
    this.domain = this.getAttribute('publication');
    this.locale = this.getAttribute('locale');
    this.mypage = this.getAttribute('mypage');
    const links = this.getAttribute('links');
    const isLoggedIn = this.getAttribute('isLoggedIn') === 'true';

    this.links = links ? JSON.parse(decodeURIComponent(links)) : [];

    this.render(isLoggedIn);

    window.addEventListener('aid-menu-open', (e) => {
      this.toggleMenuOpen(e);
    });
  }

  toggleMenuOpen(e) {
    if (e.detail) {
      this.setAttribute('style', 'display:block');
      const height = this.scrollHeight;
      if (transition) {
        const end = () => {
          this.style.height = `${height}px`;
          this.removeEventListener(transition, end);
        };
        this.style.height = `${height + 30}px`;
        this.addEventListener(transition, end);
      } else {
        this.style.height = `${height}px`;
      }
    } else {
      this.style.height = '0px';
      this.addEventListener(
        transition,
        () => {
          this.setAttribute('style', 'display:none');
        },
        { once: true }
      );
    }
  }

  render(isLoggedIn) {
    this.setAttribute('style', 'display:none');
    const links = this.links.filter((link) => link.authenticated);
    if (this.mypage) {
      links.push({ url: this.mypage, text: 'Mine abonnement' });
    }

    links.push({ url: aidUrls.profile.href, text: 'Min aID' });
    links.push({
      url: aidUrls.privacyPolicy.href,
      text: 'Personvern',
    });

    const linkshtml = links
      .map((link) => `<li><a href="${link.url}">${link.text}</a></li>`)
      .join('');
    const linkList = document.createElement('ul');
    linkList.insertAdjacentHTML('beforeend', linkshtml);

    if (isLoggedIn) {
      const element = document.createElement('a');
      // href attribute below is not an actual url, <a> element needs a href to be focusable
      element.setAttribute('href', `${window.location.href}/logg_ut`);
      element.textContent = 'Logg ut';
      element.classList.add('element', 'lp_logout_globy');
      element.addEventListener('click', (e) => {
        e.preventDefault();
        logout().then(() => {
          window.location.hash = '';
          window.location.reload();
        });
      });
      const li = document.createElement('li');
      li.appendChild(element);
      linkList.appendChild(li);
    }
    this.appendChild(linkList);
  }

  connectedCallback() {
    // TODO move this to constructor when transpiler is updated
    // to support classes extending HTML element

    if (!this._created) {
      this.init();
    }

    this._created = true;
  }
}

if (customElements && customElements.define) {
  customElements.define('amedia-aidmenu', AidMenu);
}
